import { useTranslation } from 'react-i18next';

import { BlogItem } from '../../components/BlogItem';
import { SEO } from '../../components/SEO';

import { Props } from './types';

export function Tags({
  data: {
    allMdx: {
      edges,
    },
  },
  pageContext: {
    tag,
  },
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        description={t('template.tags.seo.description')}
        title={t('template.tags.seo.title', { tag })}
      />
      <h1>
        {t('template.tags.title', { tag })}
      </h1>
      {edges.map(({ node }, index) => <BlogItem key={`item${index}`} node={node} />)}
    </>
  )
}
