import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import { getJournalPath } from '../../constants/path';

import { Styles } from './styled';
import { Props } from './types';

export function BlogItem({
  node: {
    excerpt,
    frontmatter: {
      date,
      description,
      featuredImage,
      featuredImageAlt,
      path,
      title,
    },
  },
}: Props) {
  const { t } = useTranslation();

  const image = getImage(featuredImage);
  const journalPath = getJournalPath(path);

  return (
    <Styles>
      {image && (
        <figure>
          <Link tabIndex={-1} to={journalPath}>
            <span className="sr-only">{title}</span>
            <GatsbyImage alt={featuredImageAlt} image={image} loading="lazy" />
          </Link>
        </figure>
      )}
      {title && (
        <h2>
          <Link tabIndex={-1} to={journalPath}>{title}</Link>
        </h2>
      )}
      <div>
        <p>{description || excerpt}</p>
        {journalPath && (
          <div className="meta">
            <Link className="btn-link" to={journalPath}>
              {t('component.blogItem.button')}
            </Link>
            <h4>{date}</h4>
          </div>
        )}
      </div>
    </Styles>
  )
}
