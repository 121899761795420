import styled from 'styled-components';

export const Styles = styled.article`
  display: grid;
  grid-gap: var(--spacing) 0;
  margin: calc(var(--spacing) * 4) 0;

  @media (min-width: 768px) {
    grid-gap: calc(var(--spacing) * 2) 0;
    margin: calc(var(--spacing) * 6) 0;
  }

  @media (min-width: 1200px) {
    grid-gap: calc(var(--spacing) * 3) 0;
    margin: calc(var(--spacing) * 8) 0;
  }

  > figure {
    background-color: #000;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    margin: 0;
    opacity: 1;
    overflow: hidden;

    img {
      transition: transform var(--transSlow) ease, opacity var(--transSlow) ease !important;
      opacity: 1;
    }
  }

  &:hover {
    > figure {
      img {
        transform: scale(1.1);
        opacity: .5 !important;
      }
    }
  }

  > h2 {
    &:nth-child(1) {
      grid-row: 1 / 2;
    }

    &:not(:nth-child(1)) {
      grid-row: 2 / 3;
    }

    font-size: var(--h3);
    grid-column: 1 / 3;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  > div {
    &:nth-child(2) {
      grid-row: 2 / 3;
    }

    &:not(:nth-child(2)) {
      grid-row: 3 / 4;
    }

    grid-column: 1 / 3;

    p {
      margin-top: 0;
    }

    @media (min-width: 768px) {
      margin-top: calc(var(--spacing) * -1);

      p {
        margin-bottom: calc(var(--spacing) * 2);
      }
    }

    @media (min-width: 1200px) {
      margin-top: calc(var(--spacing) * -2);
    }
  }

  .meta {
    display: flex;
    justify-content: space-between;

    h4 {
      color: var(--cTertiary);
      font-size: var(--h5);
      margin: 0;
    }
  }
`;
