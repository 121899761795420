export const getJournalPath = (path?: number | string) => {
  if (path === undefined) {
    return '';
  }

  if (path === 1) {
    return '/journal';
  }

  if (typeof path === 'number') {
    return `/journal/${path}`;
  }

  const join = path.startsWith('/') ? '' : '/';
  return `/journal${join + path}`;
};
